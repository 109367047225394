import React, { useEffect, useState, } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";

import { Pagination, TagsInput } from '@mantine/core';
import { Modal, Box } from "@mui/material";
import { v4 as uuidv4 } from "uuid";

import Filters from "./filters/Filters";
import MenuItems from "../navbar/MenuItems";
import ProductCard from "./productCard/productCard";

import { getCurrency } from "../../redux/actions/utilities/currencyAction";
import { getPriceLists } from "../../redux/actions/utilities/priceListAction";
import {
    getProducts, //  getProductsFilters 
} from "../../redux/actions/productos/productsAction";

import iconWhiteCross from "../../assets/svg/functionalities-icons/cross_white.svg";
import iconoItemNoEncontrado from "../../assets/svg/system-images/no-encontrado-41.svg";

import "./productos.css";
import ProductRecommendation from "../recommendations/productRecommendation/ProductRecommendation";
import { CurrencyCard } from "../recommendations/currencyCard/CurrencyCard";
import { QuotationSelector } from "./quotationSelector/QuotationSelector";
import { LoadingProductCard } from "./loadingProductCard/LoadingProductCard";

export default function Products({ indexID, indexPath }) {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();

    const currency = useSelector((state) => state.currency.data);
    const priceLists = useSelector((state) => state.priceList.priceList);
    const productsReducer = useSelector((state) => state.products);

    const [activePage, setPage] = useState(searchParams.get('page') !== null ? parseInt(searchParams.get('page')) : 1);
    const [selectedPricelist, setSelectedPricelist] = useState({ name: "", percentage: 0 });
    const [tags, setTags] = useState(productsReducer.filterSearch);
    const [search, setSearch] = useState(searchParams.get('textSearch') !== null ? searchParams.get('textSearch') : "");

    const [openModal, setOpenModal] = useState(false)

    const [actualNotFound, setActualNotFound] = useState(false)

    useEffect(() => {
        dispatch(getCurrency());
        dispatch(getPriceLists());
        dispatch(getProducts(
            indexID,
            {
                isSupplier: searchParams.get('isSupplier') !== null ? searchParams.get('isSupplier') === "true" : true,
                inStock: searchParams.get('inStock') !== null ? searchParams.get('inStock') === "true" : true,
                hasPrice: searchParams.get('hasPrice') !== null ? searchParams.get('hasPrice') === "true" : true,
                isUpdated: searchParams.get('isUpdated') !== null ? searchParams.get('isUpdated') === "true" : true,
                page: searchParams.get('page') !== null ? parseInt(searchParams.get('page')) : 1,
                currency: productsReducer.currency,
                minPrice: searchParams.get('minPrice') !== null ? parseFloat(searchParams.get('minPrice')) : "",
                maxPrice: searchParams.get('maxPrice') !== null ? parseFloat(searchParams.get('maxPrice')) : "",
                textSearch: searchParams.get('textSearch') !== null ? searchParams.get('textSearch') : "",
                filterSearch: searchParams.get('filterSearch') !== null ? searchParams.get('filterSearch').split(" ").map(item => item.replace("_", " ")) : [],
            }
        ));
        //dispatch(getProductsFilters(indexID));
        document.title = "Scope | Lista de productos"

        setTimeout(() => {
            setActualNotFound(true)
        }, 500);
    }, [dispatch]);

    useEffect(() => {
        setTags(searchParams.get('filterSearch') !== "" && searchParams.get('filterSearch') !== null ? searchParams.get('filterSearch').split(" ").map(item => item.replace("_", " ")) : []);
        setSearch(searchParams.get('textSearch'))
        setPage(!searchParams.get('page') ? 1 : parseInt(searchParams.get('page')))
    }, [searchParams]);

    useEffect(() => {
        if (priceLists[0]) setSelectedPricelist(priceLists[0]);
    }, [priceLists]);

    const handleSearchClick = () => {
        if (location.pathname !== "/allProducts" || search !== productsReducer.textSearch) {
            let maxPrice, minPrice, isSupplier, hasPrice, isUpdated, inStock, filterSearch;

            if (searchParams.get('maxPrice') !== null) {
                if (productsReducer.maxTotalPrice === null || parseFloat(searchParams.get('maxPrice')) <= productsReducer.maxTotalPrice) {
                    maxPrice = parseFloat(searchParams.get('maxPrice'));
                } else {
                    maxPrice = productsReducer.maxTotalPrice !== null ? productsReducer.maxTotalPrice : "";
                }
            } else {
                maxPrice = "";
            }

            if (searchParams.get('minPrice') !== null) {
                if (productsReducer.minTotalPrice === null || parseFloat(searchParams.get('minPrice')) >= productsReducer.minTotalPrice) {
                    minPrice = parseFloat(searchParams.get('minPrice'));
                } else {
                    minPrice = productsReducer.minTotalPrice !== null ? productsReducer.minTotalPrice : "";
                }
            } else {
                minPrice = "";
            }

            isSupplier = searchParams.get('isSupplier') !== null ? searchParams.get('isSupplier') === "true" : true;
            hasPrice = searchParams.get('hasPrice') !== null ? searchParams.get('hasPrice') === "true" : true;
            isUpdated = searchParams.get('isUpdated') !== null ? searchParams.get('isUpdated') === "true" : true;
            inStock = searchParams.get('inStock') !== null ? searchParams.get('inStock') === "true" : true;
            filterSearch = searchParams.get('filterSearch') !== null ? searchParams.get('filterSearch').split(" ").map(item => item.replace("_", " ")) : [];

            dispatch(getProducts(indexID, {
                ...productsReducer,
                textSearch: search,
                minPrice: minPrice,
                maxPrice: maxPrice,
                inStock: inStock,
                isSupplier: isSupplier,
                filterSearch: filterSearch,
                hasPrice: hasPrice,
                isUpdated: isUpdated,
                currency: productsReducer.currency,
                page: 1
            }));
            searchParams.set("textSearch", search);
            setSearchParams(searchParams);
        }
    }

    const handleTagInputChange = (value) => {
        if (value.length > tags.length) {
            setSearch(value[value.length - 1]);
            searchParams.set("textSearch", value[value.length - 1]);
            setSearchParams(searchParams);
        }
    };

    const handleRemove = (item) => {
        setTags(oldTags => {
            return oldTags.filter(tag => tag !== item)
        })

        let filterSearch = productsReducer.filterSearch ? productsReducer.filterSearch.filter(filter => filter !== item) : []

        dispatch(getProducts(indexID, { ...productsReducer, filterSearch: filterSearch, page: 1 }));

        filterSearch = filterSearch.map(item => item.replace(" ", "_"));
        searchParams.set("filterSearch", filterSearch.join(" "));
        setSearchParams(searchParams);
    }

    const handlePathPrice = (event) => {
        setPage(event)

        dispatch(getProducts(indexID, { ...productsReducer, page: event }))
        searchParams.set("page", event);
        setSearchParams(searchParams);
    }

    const handleCloseRecommendationModal = (event) => {
        if (event.target.className === 'producto-recomendaciones-seccion-recomendaciones') {
            setOpenModal(false)
        }
    }

    return (
        <div className="products">
            <MenuItems />
            <div className="productos-sin-accesos_rapidos">
                <div className="products-left-container">
                    <div>
                        <QuotationSelector />
                        <Filters productIndex={indexID} setSelectedPricelist={setSelectedPricelist} />
                    </div>
                </div>
                <div className="productos-contenedor-central">
                    <div className="productos-contenedor-barra_busqueda">
                        <TagsInput
                            value={tags}
                            searchValue={search || ""}
                            onSearchChange={(event) => setSearch(event)}
                            onChange={handleTagInputChange}
                            placeholder="Escribe aquí"
                            splitChars={[]}
                            onRemove={handleRemove}
                            onKeyDown={(event) => { if (event.key === 'Enter') handleSearchClick() }}
                            label={`Buscando en: ${indexPath === "allProducts" ? "Todos los productos" : indexPath.replaceAll("-", " > ").replaceAll("_", " ")}`}
                            styles={{
                                input: {
                                    backgroundColor: '#fff'
                                },
                                label: {
                                    color: '#555555',
                                    fontSize: "12px"
                                }
                            }}
                        />
                        <button onClick={handleSearchClick}>Buscar</button>
                    </div>
                    {productsReducer.products.length > 0 && !productsReducer.loading &&
                        <span className="products-totalQuantity">Total de productos: <b>{productsReducer.count}</b></span>
                    }
                    <div className={`products-cards-container ${productsReducer.products.length === 0 ? "products-noProducts-display" : ""}`}>
                        {productsReducer.loading ?
                            Array.from({ length: 12 }, () => { return <LoadingProductCard key={uuidv4()} />; })
                            : productsReducer.products?.map((product) => {
                                return <ProductCard key={product._id} item={product} selectedPricelist={selectedPricelist} />;
                            })
                        }
                        {actualNotFound && productsReducer.products.length === 0 && (
                            <div style={{ margin: "auto" }}>
                                <h3 style={{ fontSize: "20px" }}>No se encuentran artículos</h3>
                                <img src={iconoItemNoEncontrado} alt="item_not_found" />
                            </div>
                        )}
                    </div>
                    {Math.ceil(productsReducer.count / 12) > 1 &&
                        <div className="pagination-container">
                            <Pagination
                                color="var(--color-gray-blue-dark)"
                                onChange={(event) => handlePathPrice(event)}
                                total={Math.ceil(productsReducer.count / 12)}
                                value={activePage}
                            />
                        </div>
                    }
                </div>
                <div className="producto-recomendaciones-seccion">
                    <div>
                        <ProductRecommendation actualPercentage={selectedPricelist.percentage} />
                        {currency && <CurrencyCard />}
                    </div>
                </div>

                <div onClick={() => setOpenModal(!openModal)} className="producto-icono-recomendaciones">$</div>
                <Modal open={openModal} key={uuidv4()}>
                    <Box>
                        <div className="selectedProduct-modal-container">
                            <div className="producto-recomendaciones-seccion-iconos">
                                <img src={iconWhiteCross} onClick={() => setOpenModal(!openModal)} alt="iconos-cruz" />
                            </div>
                            <div className="producto-recomendaciones-seccion-recomendaciones" onClick={(event) => handleCloseRecommendationModal(event)}>
                                <ProductRecommendation actualPercentage={selectedPricelist.percentage} />
                                {currency && <CurrencyCard />}
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div>
        </div>
    );
}